import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactFormService } from 'src/app/contact-form.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home-eight',
  templateUrl: './home-eight.component.html',
  styleUrls: ['./home-eight.component.scss']
})
export class HomeEightComponent implements OnInit {

  archivos = []
  evento:any
  solicitud:any

  constructor(private titleService: Title, private route: ActivatedRoute, private contactService: ContactFormService,private router: Router, private translateService: TranslateService) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if(params['solicitud']){
        this.contactService.getSolicitud(params['solicitud']).subscribe({
          next: (r: any) => {
            this.evento = r.message.evento
            this.archivos = this.evento.descargas
            this.solicitud = r.message.solicitud
            console.log(this.evento)


            if(this.evento.idioma == "English") {
              this.translateService.use("en");
            }
    
            if(this.evento.idioma == "Deutsch") {
              this.translateService.use("de");
            }
    
            if(this.evento.idioma == "Español") {
              this.translateService.use("es");
            }
    
            if(this.evento.idioma == "Français") {
              this.translateService.use("fr");
            }
    
            if(this.evento.idioma == "Italiano") {
              this.translateService.use("it");
            }
            if(this.evento.idioma == "Polish") {
              this.translateService.use("po");
            }
            

            this.titleService.setTitle(this.evento.nombre);

          }
        })

      }
    });
  
  }

  descargar(url:any){

    const newUrl = '/api/method/atr_marketing.solicitud.downloadFile?name='+url; // Reemplaza esto con la URL a la que deseas redirigir

    window.location.href = newUrl;



  }

  downloadCertificate(){
    const newUrl = '/api/method/atr_marketing.solicitud.downloadCertificate?name='+this.solicitud.name; // Reemplaza esto con la URL a la que deseas redirigir

    window.location.href = newUrl;
  }

}
