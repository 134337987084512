
<div class="loader-container" id="loader" *ngIf="!evento">
  <div class="loader"></div>
</div>

<div *ngIf="evento">
<div class="main-banner main-banner-cont " [ngStyle]="{ 'background-image': 'url(data:image/png;base64,' + evento.layout.background + ')' }" >
  <div class="container">
    <div class="text-center ">
      <img [src]="'data:image/png;base64,' + evento.layout.logo" alt="" style="height:80px" class="mt-5">
    </div>
    <h2 class="form-title" style="color: white;">{{ evento.nombre }}</h2> 
    <h6 class="form-title" style="color: white;">{{ evento.fecha }}</h6> 
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <table class="table mt-5" style="background-color: white;">
            <thead>
              <tr>
                <th [innerHTML]="'Title'|translate"></th>
                <th [innerHTML]="'Download'|translate"></th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let archivo of archivos">
                <td>{{ archivo.titulo }}</td>
                <td>
                  <a (click)="descargar(archivo.archivo)"><i class="fa-solid fa-download"></i></a>
                </td>
              </tr>
              <tr *ngIf="evento.certificado">
                <td [innerHTML]="'Certificate'|translate"></td>
                <td>
                  <a (click)="downloadCertificate()"><i class="fa-solid fa-download"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
