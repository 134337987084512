import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ContactFormService } from 'src/app/contact-form.service';

@Component({
  selector: 'app-draw',
  templateUrl: './draw.component.html',
  styleUrls: ['./draw.component.scss']
})
export class DrawComponent {
  public countdown = 0;
  public evento : any;
  public idEvento : any;
  public participants: any[] = []
  public winner: any
  public loadingParticipant: boolean = false;
  public loadingWinner: boolean = false;

  constructor(private titleService: Title, private route: ActivatedRoute, private router: Router, private contactService: ContactFormService) {}

  ngOnInit() {

    this.route.params.subscribe(params => {
      if(params['id']){
        this.idEvento = params['id']
      }
    });
    this.contactService.getEvento({'name':this.idEvento}).subscribe({
      next: (r: any) => {
        if(r.status == 404) {
          this.router.navigate(['/event-not-found'])
        }
        this.evento = r.message;
        this.titleService.setTitle(this.evento.nombre);

        console.log(this.evento);
      },
      error: (e) =>{
        if(e.status == 404) {
          this.router.navigate(['/event-not-found'])
        }
      }
    })
  }

  sortear() {
    this.winner = ""
    if(!this.participants) {
      alert("First list the partipants.");
      return;
    }

    this.getWinner();
    /*this.countdown = 3;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.getWinner();
      }
    }, 1000);*/
  }

  getWinner() {
    this.loadingWinner = true;
    this.contactService.getWinner(this.idEvento).subscribe({
      next: (r: any) => {
        this.countdown = 3;
        const countdownInterval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            clearInterval(countdownInterval);
            this.loadingWinner = false;
            this.winner = r.message
          }
        }, 1000);
      },
      error: (e: any) => {
        this.loadingWinner = false;
        console.log(e);
      }
    })
  }

  getParticipants() {
    this.loadingParticipant = true;
    this.contactService.getParcipants(this.idEvento).subscribe({
      next: (r: any) => {
        this.loadingParticipant = false;
        this.participants = r.message
      },
      error: (e: any) => {
        this.loadingParticipant = false;
        console.log(e);
      }
    })
  }
}
