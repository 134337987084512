    <div class="main-banner-home">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="main-banner-text-ci">
                    <h1 class="mobile-font-size pc-font-size" style="color:#ffffff; margin-top: 0.1em;margin-bottom: 0em;">C&I Solutions</h1>
                    <p class="text-center" style="color: #EFE6C0; margin-top: 0; font-size: large;">We offer bespoke commercial and industrial products.</p>
                </div>
            </div>
        </div>
    </div>
<div class="bg-fffdfd pt-70 pb-70" id="welcome-raion">
    <div class="container resp-mt-2">
        <div class="section">
            <div class="parrafos">
                <div class="col-lg-6 welcome-title1">
                    <h1><span class="borders-bottom-green">Com</span>ercial & Industrial</h1>
                </div>
                <div class="col-lg-6 mt-3 fuente-pop-reg welcome-title2">
                    <p>Two different product ranges according to your needs. The C&I Solutions are developed hand in hand with BYD.</p>
                </div>
            </div>
            <br>
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-6">
                    <div class="single-services-byd space-img">
                        <div class="services-content">
                            <h3 class="fuente-cool-light">Chess Pro</h3>
                            <small class="borders-bottom fuente-pop-reg">200kW/215kWh (100 kW charge)</small>
                        </div>
                        <div class="container-img text-center">
                            <img src="assets/img/ChessPro.jpg" class="" alt="services-img" height="max-height: 400px; max-width: 100%;">
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-6">
                    <div class="single-services-byd">
                        <div class="services-content">
                            <h3 class="fuente-cool-light">MC Cube</h3>
                            <small class="borders-bottom fuente-pop-reg">200kW/447kWh</small>
                        </div>
                        <div class="container-img text-center">
                            <img src="assets/img/MCCube.jpg" class="" alt="services-img" height="max-height: 400px; max-width: 100%;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="p100-70 pt-100 main-banner-home-ci" style="background-color: #0B1F0D;">
    <div class="container">
        <div class="section ">
            <div class="battery">
                <span class="KNOW-HOW">CEA & EFT <br> KNOW-HOW</span>
            </div>
            <br><br>
            <div style="text-align: center; width: 100%;">
                <small style="display: inline-block; width: 100%; max-width: 600px; text-align: center; line-height: 1.5; font-size: 0.7rem;color:#ffffff" class="fuente-pop-reg">
                    Designing the best Control and Instrumentation (C&I) solution requires carefully selecting the right combination of components. Once configured, the complete solution is delivered to the site for installation. Then, the system is commissioned to ensure it delivers the necessary functions effectively. This approach ensures an optimal and safe system, ready for operation and benefit to all.
                </small>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="section ">
            <div class="d-flex justify-content-center">
                <a href="https://eft-systems.de/de/" target="_blank">
                    <button type="submit" style="height: 40px; width: 230px;" class="btn boton boton-ci fuente-pop-reg">VISIT EFT →</button>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="main-banner-ci" style="background-color: #0B1F0D;">
    <div class="container " style="color:#ffffff; border-bottom:#ffffff">
        <div class="row">
            <div class="col-md-6 col-sm-12 ico-pad">
                <div class="card-ci" style="border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">
                    <div class="card-header-ci table-title" style="background-color: #0B1F0D; border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">C&I Applications</div>
                    <div class="card-body-ci" style="background-color: #0B1F0D; border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">
                        <div class="row">
                            <div class=" ico-pad col-md-6">
                                <img src="assets/img/Intelligentenergymanagement.svg" alt="Imagen 1" width="50">
                                <small>Intelligent energy management</small>
                            </div>
                            <div class="col-md-6 ico-pad">
                                <img src="assets/img/Easy plug-and-play installation.svg" alt="Imagen 2" width="50">
                                <small>Easy plug-and-play installation</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ico-pad">
                                <img src="assets/img/10 years capacity warranty.svg" alt="Imagen 3" width="50">
                                <small>10 years capacity warranty</small>
                            </div>
                            <div class="col-md-6 ico-pad">
                                <img src="assets/img/Safe battery chemistry.svg" alt="Imagen 4" width="50">
                                <small>Safe battery chemistry</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-right ico-pad">
                                <img src="assets/img/Top technical performance.svg" alt="Imagen 5" width="50">
                                <small>Top technical performance</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4"  >
                <div class="card-ci mt-2" style="border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">
                    <div class="card-header-ci table-title" style="background-color: #0B1F0D; border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">C&I Applications</div>
                    <div class="card-body-ci" style="background-color: #0B1F0D; border: solid 0.5px; border-radius: 5px; border-color: #ffffff41">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-12 ico-pad">
                                                <img src="assets/img/Renewable self consumption.svg" alt="Imagen 6" width="50">
                                            <small>Renewable self consumption</small>
                                            </div>
                                        
                                        <div class="col-md-6 ico-pad">
                                            <img src="assets/img/Time of use.svg" alt="Imagen 7" width="50">
                                            <small>Time of use</small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 ico-pad">
                                            <img src="assets/img/Peak shaving.svg" alt="Imagen 8" width="50">
                                            <small>Peak shaving</small>
                                        </div>
                                        <div class="col-md-6 ico-pad">
                                            <img src="assets/img/Back-up power.svg" alt="Imagen 9" width="50">
                                            <small>Back-up power</small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 ico-pad">
                                            <img src="assets/img/EV charging optimization.svg" alt="Imagen 10" width="50">
                                            <small>EV charging optimization</small>
                                        </div>
                                        <div class="col-md-6 ico-pad">
                                            <img src="assets/img/Frequency regulation.svg" alt="Imagen 10" width="50">
                                            <small>Frequency regulation</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
</div>

<div class="pb- pt-100 " style="background-color: #ffffff; ">
    <div class="section  ptb-100">
<div class="battery" >
    <span style="opacity: 0.5;">Services <br> O&M</span><br>
    </div>         
    <br><br> 
    <div class="container">
        <div class="row " style="padding-left: 100px; padding-right: 100px;">
            <div class="col-md-4 mb-4 ml-5">
                <div class="">
                    <div class="icon-border text-center" style="vertical-align: top;">
                        <img src="assets/img/Educate.svg" height="100" alt="services-img">
                    </div>
                    <div class="text-right"><br>
                        <h3 class="mb-3">Educate</h3>
                        <small class="fuente-pop-reg">· Installer on site training <br> · Re-train e-learning <br> · End customer materials</small>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4 ml-5">
                <div class="">
                    <div class="icon-border text-center" style="vertical-align: top;">
                        <img src="assets/img/Support.svg" height="100" alt="services-img">
                    </div>
                    <div class="text-left"><br>
                        <h3 class="mb-3">Support</h3>
                        <small class="fuente-pop-reg">· Bespoke app and monitoring <br> · Connected service portal <br> · Remote support</small>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4 mr-5">
                <div class="">
                    <div class="icon-border text-center" style="vertical-align: top;">
                        <img src="assets/img/Fix.svg"  height="100" alt="services-img">
                    </div>
                    <div class=""><br>
                        <h3 class="mb-3">Fix</h3>
                        <small class="fuente-pop-reg">· Spare parts management <br> · Repair <br> · (On-site support OPTIONAL)</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
</div>
<div class="pt-70 pb-70" style="background-color:#0B1F0D;">
    <div class="container">
        <div class="section">
    <div class="parrafos">
        <div class="col-lg-6 welcome-title1">
            <h1 class="no-wrap-resp"style="color: white; font-size:60px; text-align:start">Want to learn more about RaiOn products?</h1>
            <div class="col-lg-12 col-md-12 ">
                <a href="https://raion.energy/" target="_blank"><button type="submit"style="height: 40px; width:230px;" class="btn boton fuente-pop-reg" class="btn boton">VISIT RAION →</button></a>
            </div>
    </div>
    </div>
</div>
</div>
</div>
