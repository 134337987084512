import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/BydBatteryBox/home-one.component';
import { HomeSixComponent } from './components/Contact/home-six.component';
import { HomeEightComponent } from './components/Download/home-eight.component';
import { DrawComponent } from './components/draw/draw.component';

const routes: Routes = [
    { path: 'event-not-found', component: HomeOneComponent },
    { path: 'downloads/:solicitud', component: HomeEightComponent }, 
    { path: ':id', component: HomeSixComponent },
    { path: ':id/draw', component: DrawComponent }
];


@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }