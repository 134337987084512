
<div class="loader-container" id="loader" *ngIf="!evento">
    <div class="loader"></div>
  </div>

<div *ngIf="evento">
    <div class="main-banner-cont " [ngStyle]="{ 'background-image': 'url(data:image/png;base64,' + evento.layout.background + ')', 'height': '150vh' }">
        <div class="text-center ">
            <img [src]="'data:image/png;base64,' + evento.layout.logo" alt="" style="height:80px" class="mt-3">
        </div>
                    <div >
                            <div class="row align-items-center" style="width: 100%;">
                                <div *ngIf="evento" class="col-lg-12 col-md-12">

                                    <form [formGroup]="contactForm" (ngSubmit)="submit()" *ngIf="formularioEnviado == false" class="container pad-form" >
                                        <div>
                                        <h2 class="form-title" style="color: white;">{{ evento.nombre }}</h2> 
                                        <h6 class="form-title" style="color: white;">{{ evento.fecha }}</h6>
                                        <div style="color: white;" [innerHTML]="description"></div>
                                    </div>
                                        <div class="row mt-2">
                                            <div class="col-lg-6">
                                                <label for="email"></label>
                                                <input type="email" formControlName="email" [placeholder]="'Email' |translate" required style="border-radius:0px" class="input-resp">
                                                <div *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || isSubmitted)" class="alert">
                                                <small class="" style="color:white" *ngIf="contactForm.controls['email'].errors.required"[innerHTML]="'Please enter Your email'|translate">
                                                
                                                </small>
                                                <small class="text-danger" *ngIf="contactForm.controls['email'].errors.email"[innerHTML]="'Please enter a valid Email'|translate">
                                                    
                                                    </small>
                                            </div> 
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-6">
                                                <label for="first-name"></label>
                                                <input type="text" formControlName="telefono" [placeholder]="'Teléfono' |translate" required style="border-radius:0px"class="input-resp">
                                                <div *ngIf="contactForm.controls['telefono'].invalid && (contactForm.controls['telefono'].dirty || contactForm.controls['telefono'].touched || isSubmitted)" class="alert">
                                                <small class=""style="color:white" *ngIf="contactForm.controls['telefono'].errors.required" [innerHTML]="'Please enter Your Phone Number'|translate">
                                                
                                                </small>
                                            </div>  
                                            </div>
                                            </div>
                                        <div class="row form-group">
                                            <div class="col-lg-6">
                                                <label for="first-name"></label>
                                                <input minlength="3" maxlength="50" type="text" formControlName="first_name" [placeholder]="'First Name' |translate" required style="border-radius:0px"class="input-resp">
                                                <div *ngIf="contactForm.controls['first_name'].invalid && (contactForm.controls['first_name'].dirty || contactForm.controls['first_name'].touched || isSubmitted)" class="alert">
                                                <small class=""style="color:white" *ngIf="contactForm.controls['first_name'].errors.required" [innerHTML]="'Please enter Your first name'|translate">
                                                
                                                </small>
                                            </div>  
                                            </div>
                                            </div>
                                            <div class="row">
                                            <div class="col-lg-6">
                                                <label for="surname"></label>
                                                <input minlength="3" maxlength="50" type="text" formControlName="surname" [placeholder]="'Surname' |translate" required style="border-radius:0px" class="input-resp">
                                                <div *ngIf="contactForm.controls['surname'].invalid && (contactForm.controls['surname'].dirty || contactForm.controls['surname'].touched || isSubmitted)" class="alert">
                                                <small style="color:white" *ngIf="contactForm.controls['surname'].errors.required"[innerHTML]="'Please enter Your surname'|translate">
                                                
                                                </small>
                                            </div> 
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label for="company-name"></label>
                                                <input class="input-resp" type="text" formControlName="company_name" [placeholder]="'Company Name' |translate" required style="border-radius:0px">
                                                <div *ngIf="contactForm.controls['company_name'].invalid && (contactForm.controls['company_name'].dirty || contactForm.controls['company_name'].touched || isSubmitted)" class="alert">
                                                <small style="color:white" *ngIf="contactForm.controls['company_name'].errors.required"[innerHTML]="'Please enter Your company name'|translate">
                                                </small>
                                            </div> 
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label for="city"></label>
                                                <input class="input-resp" type="text" formControlName="city" [placeholder]="'City' |translate" required style="border-radius:0px">
                                                <div *ngIf="contactForm.controls['city'].invalid && (contactForm.controls['city'].dirty || contactForm.controls['city'].touched || isSubmitted)" class="alert">
                                                <small style="color:white" *ngIf="contactForm.controls['city'].errors.required"[innerHTML]="'Please enter your city'|translate">
                                                </small>
                                            </div> 
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label for="company-name"></label>
                                                <select class="input-resp" formControlName="country" required style="border-radius: 0px; border: none; width: 36em; height: 3em">
                                                    <option [innerHTML]="'Select a country' | translate">
                                                    <option *ngFor="let pais of evento.layout.countries | sortByOrder:'asc':'countries'" value="{{ pais.countries }}">{{ pais.countries }}</option>
                                                </select>
                                                
                                                <div *ngIf="contactForm.controls['country'].invalid && (contactForm.controls['country'].dirty || contactForm.controls['country'].touched || isSubmitted)" class="alert">
                                                    <small style="color:white" *ngIf="contactForm.controls['country'].errors.required"[innerHTML]="'Please enter Your Country'|translate"> 
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <form [formGroup]="contactForm" (ngSubmit)="submit()">                                      
                                            <div class="form-check">
                                            <input class="form-check-input" type="checkbox" formControlName="flexCheckChecked">
                                            <label style="color:white" class="form-check-label" for="flexCheckChecked">
                                                {{privacy_policy}}
                                            </label>
                                            </div>
                                            <div *ngIf="contactForm.get('flexCheckChecked').hasError('required')">
                                            <p style="color:white"[innerHTML]="'This field is mandatory'|translate"></p>
                                            </div>
                                            </form>
                                        <div class="row justify-content-end "> 
                                            <div class="col-lg-12 col-md-12 text-right" *ngIf="formLoading">
                                                <button type="submit" style="height: 2rem;padding-left:5em; padding-right:5em; border-radius:0px" class="mt-3 btn boton"[innerHTML]="'Download presentation'|translate" disabled></button>
                                            </div>
                                            <div class="col-lg-12 col-md-12 text-right" *ngIf="!formLoading">
                                                <button type="submit" style="height: 2rem;padding-left:5em; padding-right:5em; border-radius:0px" class="mt-3 btn boton"[innerHTML]="'Download presentation'|translate"></button>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="row" *ngIf="formularioEnviado == true">
                                        <div class="col-lg-12 col-md-6">
                                            <div class="form-group mb-3 mt-3 text-center" *ngIf="!evento.certificado">
                                                <p style="color:white"[innerHTML]="'You will shortly receive an email with the link to the presentation'|translate"></p>
                                            </div>
                                            <div class="form-group mb-3 mt-3 text-center" *ngIf="evento.certificado">
                                                <p style="color:white"[innerHTML]="'You will shortly receive an email with the link to the presentation and certificate'|translate"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
</div>
