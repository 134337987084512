
<div class="main-banner-cont  "style="background-image: url(assets/img/Home1.jpg); height:100vh" >
  <div class="container">
  <div class="d-table" >
    <div class="d-table-cell">
          <div class="container">
            <div class="d-flex justify-content-center align-items-center" style="height: 100vh;">
                <h1  style="color:white">Event not found</h1>
                  </div>
                  </div>
                  
              </div>
          </div>
      </div>
      </div>
  