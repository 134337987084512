
<div class="loader-container" id="loader" *ngIf="!evento">
    <div class="loader"></div>
</div>
<div *ngIf="evento">
    <div class="main-banner-cont ">
        <div class="row align-items-center text-center" style="width: 100%;">
            <div class="col-lg-4"></div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4"><img src="assets/img/byd-logo.png" /></div>
        </div>
        <div class="row mt-3 align-items-center text-center" style="width: 100%;">
            <div class="col-lg-6">
                <button [disabled]="loadingParticipant" (click)="getParticipants()" class="red-button mb-3">GATHER PARTICIPANTS</button>
            </div>
        </div>
        <div class="row mt-3 align-items-center text-center">
            <div class="col-lg-6">
                <div class="participants">
                    <div *ngIf="participants.length > 0 && !loadingParticipant" style="height: 330px; overflow-y: scroll; width: 480px; margin: auto;">
                        <p *ngFor="let participant of participants">{{participant}}</p>
                    </div>
                </div>
                
            </div>
            <div class="col-lg-6 winner">
                <div class="countdown" *ngIf="countdown > 0">
                    <p>{{countdown}}</p>
                </div>
                <div class="box-winner" *ngIf="winner">
                    <h2>CONGRATULATIONS!</h2>
                    <h2 class="mt-5" style="font-weight: bold; color: red;">{{winner.nombre }}</h2>
                    <p class="mt-5">{{winner.email}}</p>
                </div>
            </div>
        </div>
        <div class="row align-items-center text-center mt-3" style="width: 100%;">
            <div class="col-lg-6">
                <button [disabled]="loadingWinner" (click)="sortear()" class="red-button">CHOOSE WINNER</button>
            </div>
        </div>
    </div>
</div>
