import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(public http: HttpClient) { }

  public getCountrys(): Observable<any[]> {
    return this.http
      .get<any[]>(`/api/method/atr_marketing.solicitud.getCountrys`);
  }


  public createSolicitud(solicitud: any=''): Observable<any[]> {
    return this.http
      .post<any[]>(`/api/method/atr_marketing.solicitud.createdSolicitud`, solicitud);
  }

  public getEvento(evento: any=''): Observable<any[]> {
    return this.http
      .post<any[]>(`/api/method/atr_marketing.solicitud.getEvento`, evento);
  }

  public getArchivo(arch: any=''): Observable<any[]> {
    return this.http
      .post<any[]>(`/api/method/atr_marketing.solicitud.downloadFile`, arch);
  }

  public getSolicitud(solicitud): Observable<any[]> {
    return this.http
      .get<any[]>(`/api/method/atr_marketing.solicitud.getSolicitud?name=`+solicitud);
  }

  public getParcipants(event_id: any): Observable<any[]> {
    let data = {
      "event": event_id
    }

    return this.http
      .post<any[]>(`/api/method/atr_marketing.solicitud.getSurveyParticipants`, data);
  }

  public getWinner(event_id: any): Observable<any[]> {
    let data = {
      "event": event_id,
      "random": 1
    }
    
    return this.http
      .post<any[]>(`/api/method/atr_marketing.solicitud.getSurveyParticipants`, data);
  }

}
