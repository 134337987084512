<div class="main-banner-home "> 
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-texts">
                    <h1 class=" mobile-font-size-sm ">Powering Africa with <br > BYD Technology</h1>
                    <p >Energy storage solutions delivered and serviced locally.</p><br><br><br>
                    <div class="col-lg-12 col-md-12 ">
                        <a routerLink="/about-us"><button type="submit" class="btn btn-second">LEARN MORE →</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-banners main-banner-homes vertical-center" >
    <div class="d-table hresp">
        <div class="d-table-cell">
            <div class="container">
                <div class="card-cea"style="opacity: 0.96; height: 550px; ">
                   <div class="card-body"style="padding-top: 20px; padding-bottom: 20px;" >
                    <div class="row align-items-center ">
                      <div class="col-lg-5 col-md-12 col-sm-12">
                          <div class="about-content">
                              <div class="section-title">
                                  <h1 class="what-cea titulocea "style="text-align: left">What we do</h1><br>
                                  <p class="fuente line-cea">Collective Energy Africa is responsible for bringing the BYD BatteryBox product range from Kenya to the sub-Saharan market locally. We do not only sell, we also train, advise, follow up the product and offer technical service. Thanks to CEA the procurement and delivery process of BYD solar batteries, spare parts or other additional components is accelerated. We invite you to get to know us better.</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-7 col-md-12 text-center">
                          <div class="about-video">
                              <img class="img-what" src="assets/img/LV FLEX LITE.png" alt="about">
                              <div class="video-btn">
                              </div>
                          </div>
                      </div>
                  </div>
                  
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class=" bg-fffdfd   ptb-100">
    <div class="container">
        <div class="section">
<div class="">
    <div class="section">
        <div class="battery-cea-homes bettery mb-5">
            <span >WHY CEA?</span><br>
            </div>   
            <div class="row justify-content-center equal-height pb-100">
            <div class="col-lg-3 col-md-6">
                <div class="">
                    <div class="services-content mb-2">
                        <h3 class="borders-top">Local  <br> Presence</h3> <br>                           
                    </div>
                    <div class="services-img">
                        <img src="assets/img/LocalPresence.jpeg" height="250" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="">
                  <div class="services-content mb-2">
                      <h3 class="borders-top">Local  <br> Warehousing</h3> <br>                            
                  </div>
                  <div class="services-img">
                      <img src="assets/img/LocalWarehousing.jpg" height="250" alt="services-img">
                  </div>
              </div>
          </div>
          
<div class="col-lg-3 col-md-6">
    <div class="">
        <div class="services-content mb-2">
            <h3 class="borders-top">Local  <br> Training</h3> <br>                           
        </div>
        <div class="services-img">
            <img src="assets/img/LocalTraining.jpg" height="250" alt="services-img">
        </div>
    </div>
</div>
<div class="col-lg-3 col-md-6">
    <div class="">
        <div class="services-content mb-2">
            <h3 class="borders-top">Local  <br> Support<br></h3> <br>                           
        </div>
        <div class="services-img">
            <img src="assets/img/LocalSupport.jpg" height="250" alt="services-img">
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>
    </div>
</div>
<div class=" bg-0B1F0D  pt-100 pb-70" >
    <div class="container">
        <div class="section">
          <div class="battery-cea-home text-center mb-5">
            <span style="color:#EFE6C0; opacity:0.25" class="d-block">BYD</span>
            <span style="color:#EFE6C0; opacity:0.25" class="d-block">BATTERYBOX</span>
        </div>
        
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="services-content">
                <p style="font-size: x-large;" class="texto-blanco">LV Flex Lite</p>
                <small class="texto-crema">BYD BatteryBox 5 kWh to 320 kWh</small>
            </div>
            <div class="container-img text-center">
                <img src="assets/img/LV FLEX LITE.png" height="80%"  style="padding-top: 3em;"alt="services-img"> 
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="services-content">
              <p style="font-size: x-large;" class="texto-blanco">Premium LVL</p>
                <small class="texto-crema">BYD BatteryBox 15.4 to 983 kWh</small>
              </div>
            <div class="container-img text-center">
              <img src="assets/img/LVL Frontal.png" style="padding-top: 3em;"  alt="services-img">
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            <div class="services-content">
              <p style="font-size: x-large;" class="texto-blanco">HV Repowered</p>
                <small class="texto-crema">BYD BatteryBox 5.1 to 11.5 kWh</small>
              </div>
            <div class="container-img text-center">
              <img src="assets/img/HV-R.png" style="width: auto; height: 100%" alt="services-img">
            </div>
          </div>
        </div>
      </div>
      
    </div>
</div>
</div>
<div class="main-banner ">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-texts">
            <h2 class="texto-blanco">The simplest and easiest energy <br> storage system:</h2> <br>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-4">
                <div class="text-center"> 
                  <div class="services-img">
                    <img src="assets/img/expansion.svg" height="200" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Simple layout</h3><br>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-center">
                  <div class="services-img" style="vertical-align: top;">
                    <img src="assets/img/layout.svg" height="200px" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Simple expansion </h3><br>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-center"> 
                  <div class="services-img" style="vertical-align: top;">
                    <img src="assets/img/powerful.svg" height="200px" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Simply powerful</h3><br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
